<template>
  <v-dialog v-model="dialogVisibility" :width="maxWidth">
    <template v-if="$scopedSlots.activator" v-slot:activator="scope">
      <slot name="activator" v-bind="scope"> </slot>
    </template>
    <v-card class="mk-card-radius pa-4">
      <v-card-title class="justify-center">
        <slot name="title">
          <div class="d-flex flex-column align-center">
            <delete-icon></delete-icon>
            <div style="height: 15px;"></div>
            <p class="alert-title">Delete {{ itemText }}</p>
          </div>
        </slot>
      </v-card-title>
      <v-card-text class="alert-text">
        <slot>Are you sure you want to delete this {{ itemText }}?</slot>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn outlined rounded color="primary" @click="cancel">Cancel</v-btn>
        <v-btn
          rounded
          color="red accent-3"
          class="white--text"
          :loading="deleting"
          @click="submit"
          >Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DeleteIcon from "~ef/components/DeleteIcon.vue";
export default {
  name: "DialogDelete",
  components: { DeleteIcon },
  props: {
    value: Boolean,
    item: Object,
    itemText: { type: String, default: "Item" },
    deleting: Boolean,
    maxWidth: {
      type: String,
      default: "500px"
    }
  },
  data() {
    return {
      dialogVisibility: this.value
    };
  },
  watch: {
    dialogVisibility() {
      this.$emit("input", this.dialogVisibility);
    },
    value() {
      this.dialogVisibility = this.value;
    }
  },
  methods: {
    cancel() {
      this.dialogVisibility = false;
    },
    submit() {
      this.$emit("confirm-remove", this.item);
      this.$emit("delete", this.item);
      this.dialogVisibility = false;
    }
  }
};
</script>
<style>
.alert-title {
  font-size: 26px;
  font-weight: 500;
}
.alert-text {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  color: #3d3d3d;
}
</style>
